/**
 * QUESTION-TOPIC.MODEL.TS
 * Relationship between question and topic
 */

export class QuestionTopic{
  TopicId : string = ""
  triggerChoice : null | string = null
  triggerValue : string = "<"
  disableNoOpinion: boolean = false //hide nspp (formerly answersRequired from Template)
  
  constructor(questionTopic: Partial<QuestionTopic> = {}){
    Object.assign(this, questionTopic)
  }

}