
/**
 * CARD.TSX
 */

import { WithTranslation, withTranslation } from "react-i18next";
import { useState } from "react";
import localeEmoji from 'locale-emoji';


interface OwnProps{
  language: string
  languages: string[]
  onChange?: Function
}

type Props = OwnProps & WithTranslation

function LanguageSelector(props: Props){

  const [currentLanguage, setCurrentLanguage] = useState<string>(props.language)

  function change(newLanguage: string){
    setCurrentLanguage(newLanguage)
    if(props.onChange){
      props.onChange(newLanguage)
    }
  }

  function getEmoji(lg){
    const emoji =  localeEmoji(lg)
    return emoji ? emoji : lg.toUpperCase()
  }

  return(
    props.languages.length > 1 ?
    <div className="flex flex-wrap">
      { props.languages.map((lg: string) =>
      <div key={lg}
        onClick={() => change(lg)}
        style={
          currentLanguage === lg ?
          {
            fontWeight: 'bold',
            backgroundColor: '#3f3f3f',
            padding : '0px 8px',
            borderRadius : 8
          } :
          {}
        }
        className="_hover intro-language">
        {getEmoji(lg)}&nbsp;
        {lg.toUpperCase()}
      </div>
      )}
    </div>
    :
    <div style={{height:10}}/>
  
  )
}

export default withTranslation()(LanguageSelector)