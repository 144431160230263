/**
 * QUESTION.MODEL.TS
 * Question to display
 */

import { Proposition } from "./proposition.model"
import { QuestionTopic } from "./question-topic.model"

export class Question{
  aid : number = 0
  choiceCount : number | null = null
  done : boolean = false
  draft : boolean = false
  id : string = ""
  message : string = ""
  name : any = {}
  note : null | number = null
  order : number = 0
  Propositions : Proposition[] = []
  propositionsOrder : string[] = []
  QuestionsTopic : QuestionTopic = new QuestionTopic()
  responseCount : number = 0
  reverse : boolean = false
  special : string | null = null
  type : string = "scale"
  valueSpecial : number | null = null
  visible : boolean = false
  withScore : string = ""
  withMessage : boolean = false
  
  constructor(question: Partial<Question> = {}){
  
    if (!question) question = new Question()
    question.QuestionsTopic = new QuestionTopic(question.QuestionsTopic)
    question.Propositions = question.Propositions ? question.Propositions.map(x => new Proposition(x)) : []

    Object.assign(this, question)
    
  }

  get activePropositions():Proposition[]{
    return this.Propositions.filter(x => x.active)
  }

}