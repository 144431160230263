/**
 * CONFIG.TS
 * Local configuration
 */

export const config: { alternativeDisplayBrowsers: string[] } = {
    //Browser with alternative display
    //Some browser do not display some div, so adapt it
    alternativeDisplayBrowsers : [
        "safari",
        "ios",
        "crios"
    ]
}
