/**
 * AXIS-DISPLAYER.TSX
 */

import { DataInfo, Proposition } from "@/routes/results.route";
import { WithTranslation, withTranslation } from "react-i18next";
import Card from "./card";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import env from "@/env"
import Checkbox from "./checkbox";


interface OwnProps{
  language?: string
  questions: DataInfo[]
}

type Props = OwnProps & WithTranslation

function AxisDisplayer(props:Props) {
  const { t } = props

  //for choice questions
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([])

  const [currentLanguage, setCurrentLanguage] = useState<string>(props.language ? props.language : "fr")

  useEffect(()=>{
    if(props.language){
      setCurrentLanguage(props.language)
    }
  },[
    props.language
  ])

  function renderScale(question: DataInfo){
    return(
      <div
        className="flex"
        style={{ margin : 'auto 0px', color: "white" }}
      >
        <div className="flex1"/>

        <div style={{
          width : '100%',
        }}>

        { /** Type scale with two answers (yes/no) **/
        question.responseCount === 2 &&
        <div className="flex">

          <div className="flex1" />

          <div className="flex">

            <div className={"flex flex-dcol scale-circle scale-circle-2" + ( question.value === 0 ? " selected" : "" )}> 
              <div className="flex1" />
              <div className="flex">
                <div className="flex1" />
                {t("utils_no")}
                <div className="flex1" />
              </div>
              <div className="flex1" />
            </div>

            <div style={{width:10}}/>

            <div className={"flex flex-dcol scale-circle scale-circle-2" + ( question.value === 1 ? " selected" : "" )}>
              <div className="flex1" />
              <div className="flex">
                <div className="flex1" />
                {t("utils_yes")}
                <div className="flex1" />
              </div>
              <div className="flex1" />
            </div>

          </div>

          <div className="flex1" />

        </div>
        }

        { /** Case when question is type nps (10 values) **/
        question.responseCount === 10 &&
        <div className="flex">
          {[...Array(10)].map((e: undefined, i: number)=>
          <div key={i} className="flex flex-dcol" style={{height:50}}>
            {(i%2 !== 0) &&
            <div className="flex1"/>
            }
            <div className={"flex scale-circle scale-circle-10" + ( question.value === i ? " selected" : "" )}  >
              <div className="flex1"/>
              {(i+1)}
              <div className="flex1"/>
            </div>
            {(i%2 === 0) &&
            <div className="flex1"/>
            }
          </div>
          )}

        </div>
        }

        { /** case when response count is Likert **/
        (question.responseCount === 4 || question.responseCount === 5) &&
        <div className="flex">
          <div className="flex1"/>
          <div className="flex" style={{position:"relative"}}>

            <div className="scale-bar" />

            {[...Array(question.responseCount)].map((e: undefined, i: number) => 
            <div key={i} className={"flex flex-dcol scale-circle scale-circle-5" + ( i === question.value ? " selected" : "" )}>
              <div className="flex1" />

              <div className="flex">
                <div className="flex1" />
                  {i===0 &&
                  <FontAwesomeIcon size={"xs"} icon={question.reverse ? faThumbsUp : faThumbsDown} />
                  }
                  {i===(question.responseCount-1) &&
                  <FontAwesomeIcon size={"xs"} icon={question.reverse ? faThumbsDown : faThumbsUp} />
                  }
                <div className="flex1" />
              </div>

              <div className="flex1" />
            
            </div>
            )}
          </div>
          <div className="flex1"/>
        </div>
        }

      </div>
      
      <div className="flex1"/>

    </div>
    )
  }

  function displayResult(question: DataInfo){
    switch(question.type){
      case "choice" :
      return question.propositions.length > 0 ?
      <div style={{marginTop: 10, color:"#c9c9c9"}} className="flex">

        {expandedQuestions.includes(question.aid) ?
        <u className="_hover" onClick={() => setExpandedQuestions(expandedQuestions.filter((x: number) => x !== question.aid))}>
          {t("propositions_hide")}
        </u>
        :
        <u className="_hover" onClick={() => setExpandedQuestions([...expandedQuestions, question.aid])}>
          {t("propositions_show")}
        </u>
        }

        {question.selectedPropositions.length+"/"+question.propositions.length}
      </div>
      :
      <div>{t("propositions_empty")}</div>
      
      case "scale" :
      return <div>
        {renderScale(question)}
      </div>

      case "open" :
      return <div className="textarea-message">
        {question.message ? question.message : <i>{t("message_empty")}</i>}
      </div>

      default:
      return <div>{t("invalid_question_type")}</div>
    }
  }

  function getAxisName(){
    if(props.questions[0].axisId === "*"){
      return t("axis_filter")
    }
    else if(props.questions[0].axisName){
      return props.questions[0].axisName[currentLanguage]
    }
    else{
      return t("axis_nameless")
    }
  }

  return (
          
    props.questions.length > 0 ?
    <Card>

      <div className="flex">
        {props.questions[0].axisImage ?
        <div>
          <img src={env.REACT_APP_URL_SPACE.concat((props.questions[0].axisDefaultImage ? "/_default/" : "/Axes/"), props.questions[0].axisImage, ".png")} height={50} width={50} alt={props.questions[0].axisName} />
        </div>
        :
        <div style={(props.questions[0].axisColor ? {borderRadius: 30, width: 30, height: 30, backgroundColor: props.questions[0].axisColor} : {borderRadius: 30, width: 30, height: 30, border: "1px solid black"})} />
        }

        <div style={{width: 10}}/>

        <div className="flex flex-dcol">
          <div className="flex1"/>
          {getAxisName()}
          <div className="flex1"/>
        </div>
      </div>

      {props.questions.map((x: DataInfo)=>
      <div key={x.id}>

        <div className="flex flex-wrap" style={{ margin: "20px 10px"}}>
          <div style={{width: "100%", maxWidth: 550}}>
            <b className="topic-title" style={{ color: x.axisColor }}>{x.topicName[currentLanguage]}</b>
            <div>{x.name[currentLanguage]}</div>
          </div>
          <div className="flex1" />
          <div style={{width: x.type === "open" ? "100%" : 200, marginTop: 10}}>
            {displayResult(x)}
          </div>
        </div>

        {expandedQuestions.includes(x.aid) &&
        <div style={{marginLeft: 20, maxWidth: 800}}>
          {x.propositions.map((y: Proposition) =>
          <div key={y.aid}>
            <Checkbox
              active={x.selectedPropositions.length > 0 ? x.selectedPropositions.includes(y.aid) : false}
              id={y.aid}
              text={y.name[currentLanguage]}
            />
          </div>
          )}
        </div>
        }

      </div>
      )}

    </Card>
    :
    <div>
      {t("axis empty")}
    </div>
    
  )
  
}

export default withTranslation()(AxisDisplayer)