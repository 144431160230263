/**
 * END.ROUTE.TS
 * Page that says survey is done : display message
 */

import Page from "@/components/page"
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from 'react-router'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Session } from "@/model/session.model";
import InviteUsersWidget from "@/widgets/invite-users.widget";
import Card from "@/components/card";

interface StateProps extends WithTranslation, RouteComponentProps{
  session : Session
}

function EndRoute(props:StateProps) {
  const { t } = props
  
  function getMessage(){
    const params:any = props.match.params
    return params.message
  }

  return (
    <Page
      tabTitle={t("end_title_" + getMessage())}
    >
      <div className="flex">
        <div className="flex1" style={{ margin : '80px' }}>

          <div className="flex end-check"
            style={{ color : "#20CA7E" }}>
            <FontAwesomeIcon icon={faCheckCircle}/>
          </div>
          
          <h2>
            {t("end_title_" + getMessage())}
          </h2>

          <p>
            {t("end_message_" + getMessage())}
          </p>

        
        { //if pastResults is true in account options
        false && // props.session.resultsCode &&
        <u
          className="_hover"
          style={{ color: "#8C8C8C" }}
          //onClick={() => props.history.push(`/results?id=${props.session.resultsCode}&code=${props.session.code}`)}
          onClick={() => props.history.push(`/results?id=${props.session.resultsCode}`)}
        >
          {t("my_answers")}
        </u>
        }

        </div>
        {( props.session.role === "ADMIN" && props.session.isTest && props.session.isTrialAccount) &&
        <Card maxWidth={300}>
          <InviteUsersWidget/>
        </Card>
        }
      </div>
    </Page>
  )
  
}

const mapStateToProps = state => ({
  session : state.reducers
})

export default withRouter(connect(mapStateToProps)(withTranslation()(EndRoute)))