/**
 * INDEX.TSX
 * Root file for quickvote application
 */

import React from "react"
import { Root, createRoot } from "react-dom/client"
import thunk from "redux-thunk"
import App from "@/App"
import { Provider } from "react-redux"
import axios, { AxiosInstance } from "axios"
import cookie from "react-cookies"
import rootReducer from "@/redux/_index.reducers"
import { createStore, applyMiddleware } from "redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"
import "@/style/components.css"
import "@/style/fonts.css"
import "@/style/grid.css"
import "@/style/routes.css"
import "@/translate/i18n"
import WebFont from "webfontloader"
import apiMiddleware from "./utils/api-middleware"
import { initSession } from "@/redux/actions"
import reportWebVitals from "@/reportWebVitals"
import * as serviceWorker from "@/serviceWorker"

/* INIT */

//Export env
import env from "@/env"

/* API REST */

//Create axios instance to fetch idtree backend
export const backendInstance: AxiosInstance = axios.create({
  baseURL: env.REACT_APP_URL_BACKEND,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json"
  }
})

//Init axios (reporting service)
export const reportingInstance: AxiosInstance = axios.create({
  baseURL: env.REACT_APP_URL_REPORTING,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json"
  }
})

//Init headers of the application for cookies
//null value for idtreeToken if user have not been auth
if (!cookie.load("idtreeToken")) cookie.save("idtreeToken", "null", { path: "/" })
else backendInstance.defaults.headers.common["idtreeToken"] = cookie.load("idtreeToken")

/* REDUX */

//Init store redux
//Persist store in local storage
const persistConfig = {
  key: "root",
  whitelist: ["reducers"],
  storage
}

//Export store that will be used in every component of the app
export const store = createStore(persistReducer(persistConfig, rootReducer), applyMiddleware(apiMiddleware, thunk))

//Create persisitor
export const persistor = persistStore(store)

//Init reducers
//If not, types of object will not be setup on reload
const initReducers = () => {
  store.dispatch(initSession())
}

//Load font
WebFont.load({
  google: { families: ["Open Sans:400,600,700", "sans-serif"] }
})

const root: Root = createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <meta httpEquiv="cache-control" content="no-cache" />
    <meta httpEquiv="expires" content="0" />
    <meta httpEquiv="pragma" content="no-cache"></meta>
    <meta name="google" content="notranslate" />
    <div translate="no" className="notranslate">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={initReducers}>
          <ToastContainer autoClose={8000} position="bottom-right" hideProgressBar={true} pauseOnHover></ToastContainer>
          <App />
        </PersistGate>
      </Provider>
    </div>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
