/**
 * ENV.TS
 * Prefix environnement variable with REACT_APP
 * Override local variable with process variables
 */


class Env{
  REACT_APP_URL_APPLICATION: string = (typeof REACT_APP_URL_APPLICATION !== 'undefined') ? REACT_APP_URL_APPLICATION : "http://localhost:3000"
  REACT_APP_URL_BACKEND: string = (typeof REACT_APP_URL_BACKEND !== 'undefined') ? REACT_APP_URL_BACKEND : "http://localhost:5004"
  REACT_APP_URL_REPORTING: string = (typeof REACT_APP_URL_REPORTING !== 'undefined') ? REACT_APP_URL_REPORTING : "http://localhost:5002"
  REACT_APP_URL_SPACE: string = (typeof REACT_APP_URL_SPACE !== 'undefined') ? REACT_APP_URL_SPACE : "https://idtree-dev.fra1.digitaloceanspaces.com"
  REACT_APP_BUILD_HASH: string = (typeof REACT_APP_BUILD_HASH !== 'undefined') ? REACT_APP_BUILD_HASH : "0123456"
  REACT_APP_BUILD_DATE: number = (typeof REACT_APP_BUILD_DATE !== 'undefined') ? REACT_APP_BUILD_DATE : 1700000000000
}

//Read environnement variable to override
const env: Env = new Env()

export default env
