/**
 * PROPOSITION.MODEL.TS
 * Multiple choice response
 */

export class Proposition{
  active:boolean = false
  aid:number = 0
  attributeAid:number = 0
  attributeId:string = ""
  id:string = ""
  name:any = {}
  special:string | null = null
  valueSpecial:number | null = null
  order:number | null = null
  
  constructor(proposition: Partial<Proposition> = {}){
    Object.assign(this, proposition)
  }

}