/** 
 * INVITE-USERS.MODAL
 * add test users to test survey
 */

import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next"
import { Session } from "@/model/session.model";
import { useEffect, useState } from "react";
import { store } from "@/index";
import Button from "@/components/button";
import { addUserForTest, getInvitedUsers, invite } from "@/redux/actions";
import TextInput from "@/components/text-input";
import { User } from "@/model/user.model";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faTimes } from "@fortawesome/free-solid-svg-icons";

interface StateProps{
  session : Session
}

type Props = StateProps & WithTranslation

const emailRegex: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const MODAL_INVITE_CONFIRM: string = "MODAL_INVITE_CONFIRM"
const MODAL_INVITED_USERS: string = "MODAL_INVITED_USERS"

function InviteUsersWidget(props:Props) {
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  const [email, setEmail] = useState<string>("")
  
  const [emails, setEmails] = useState<string[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [users, setUsers] = useState<User[]>([])
  
  useEffect(() => {
    async function loadRecipients(){
      const response: any = await store.dispatch(getInvitedUsers(props.session.usersForTest.join(",")))
      if(!response.error){
        setUsers(response.map((x: any) => new User(x)))
      }

      setIsLoading(false)
    }

    loadRecipients()
  }, [
    props.session.usersForTest
  ])

  function addEmail(){
    setEmails(emails.concat(email))
    setEmail("")
  }

  function getInvitationsRemaining(){
    return props.session.ceilTesters - props.session.usersForTest.length - emails.length
  }

  function isError(){
    return email.length > 0 ? !emailRegex.test(email) : false
  }

  async function inviteTester(){
    setIsSaving(true)

    if(!isError() && !isLoading){

      for (let i = 0; i < emails.length; i++) {
        const e = emails[i]

        if(users.map((x: User) => x.email).includes(e)){
          toast(t("user_already_invited"), { type : "error" })
        }
        else{

          const response: any = await store.dispatch(invite(new User({ email : e })))
          if(response.id){
            store.dispatch(addUserForTest(response.id))
          }
          
        }
        
      }
     
      setEmails([])
      setCurrentModal(null)
      setIsSaving(false)
      toast(t("user_invited"), { type: "dark" })
      
    }
  }

  return (
    <div>

      { currentModal === MODAL_INVITE_CONFIRM &&
      <div className="save-modal-container flex"
        onClick={() => setCurrentModal(null)}>

        <div className="save-modal flex flex-dcol"
          style={{ 
            padding: "20px 40px",
            width : 520
          }}
          onClick={(e: any) => e.stopPropagation()}>

          <h3 style={{ color : "initial" }}>
            {t("survey_invite_confirm")}
          </h3>

          <div>{t("survey_invite_info")}</div>

          <ul style={{ textAlign : "initial" }}>
            { emails.map(e =>
            <li key={e}>
              {e}
            </li>
            )
            }
          </ul>

          <div className="flex1" />

          <div className="flex"
            style={{ marginTop : 22 }}>

            <Button onClick={() => setCurrentModal(null)}>
              {t("back")}
            </Button>

            <div className="flex1"/>

            <Button className="primary"
              onClick={inviteTester}>
              <div>
                { isSaving && 
                <FontAwesomeIcon icon={faCircleNotch} 
                  spin 
                  style={{
                    fontSize: 16,
                    margin: 0,
                    marginRight : 6
                  }}
                />
                }
                {t("question_confirm")}
              </div>
            </Button>

          </div>
        </div>
      </div>
      }

      { currentModal === MODAL_INVITED_USERS &&
      <div className="save-modal-container flex"
        onClick={() => setCurrentModal(null)}>
        <div className="save-modal flex flex-dcol" style={{padding: "20px 40px"}}>

          <h3 style={{ color : "initial" }}>
            {t("participants")}
          </h3>

          {(isLoading || users.length < 1)?
          <div>{t("loading")}</div>
          :
          <div style={{fontSize: 12}}>
            
            {users.map((x: User, i: number) =>
            <div key={i} className="flex">
              <div className="flex2">{x.email}</div>
              <div className="flex1">{x.firstname}</div>
              <div className="flex1">{x.lastname}</div>
            </div>
            )}
          </div>
          }

          <div className="flex"
            style={{ marginTop : 22 }}>
            <div className="flex1"/>
            <Button onClick={()=>setCurrentModal(null)}>
              {t("back")}
            </Button>
          </div>
        </div>
      </div>
      }

      <h3>{t("survey_invite")}</h3>

      { props.session.usersForTest.length < props.session.ceilTesters ?
      <div>

        <div>
          <p style={{ fontSize : 14 }}>
            {t("test_invitations_remaining", { 
              count: getInvitationsRemaining(),
              s: getInvitationsRemaining() > 1 ? "s" : ""
            })}
          </p>
          { props.session.usersForTest.length > 0 && 
          <u className="_hover"
            style={{ fontSize : 14, color : "grey" }}
            onClick={() => setCurrentModal(MODAL_INVITED_USERS)}>
            {t("utils_show_users")}
          </u> 
          }
        </div>

        <div style={{height: 20}} />

        <TextInput
          title={t("email")}
          value={email}
          onChange={(e: any) => setEmail(e.value)}
          error={isError()}
        />

        { emails.length > 0 &&
        <div>

          <p style={{ 
              fontSize : 14, 
              marginTop : 0 
            }}>
            {t("users_to_add")}
          </p>

          <ul style={{
              fontSize : 12,
              margin : 0,
              marginBottom : 22,
              paddingLeft : 12,
            }}>
            { emails.map(item =>
            <li key={item}>
              {item}
              <FontAwesomeIcon icon={faTimes} 
                style={{ marginLeft : 8 }}
                className="_hover"
                onClick={() => setEmails(emails.filter(x => x !== item))}
              />
            </li>
            )
            }
          </ul>

        </div>
        }
        
        { getInvitationsRemaining() > 0 &&
        <Button className={(email.length < 1 || isError()) ? "" : "primary"}
          onClick={() => (email.length < 1 || isError()) ? {} : addEmail()}>
          {t("user_add")}
        </Button>
        }

        <Button className={(email.length === 0 && emails.length > 0) ? "primary" : "secondary"}
          onClick={() => (email.length === 0 && emails.length > 0) ? setCurrentModal(MODAL_INVITE_CONFIRM) : {}}>
          {t("question_confirm")}
        </Button>

      </div>
      :
      <div>
        <p>{t("test_users_limit_reached", { count: props.session.usersForTest.length })}</p>
        <u className="_hover" onClick={() => setCurrentModal(MODAL_INVITED_USERS)}>{t("utils_show_users")}</u>
      </div>
      }

    </div>
  )

}

const mapStateToProps = state => ({
  session : state.reducers
})

export default connect(mapStateToProps)(withTranslation()(InviteUsersWidget))