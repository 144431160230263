/** 
 * CHECKBOX
 */

import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface OwnProps{
  active? : boolean //Is checkbox active
  id? : string | number //Id of the checkbox
  onClick? : Function | null //Function to trigger onclick
  text? : string //Text to display
}

function Checkbox(props:OwnProps) {


  //Trigger click on checkbox
  function click(event:any){
    event.stopPropagation()

    if (props.onClick){
      props.onClick({ id : props.id, value : !props.active })
    }
  }

  function getClassName(){
    let className = "checkbox-container flex"

    if (!props.onClick){
      className += " checkbox-inactive"
    }
    else{
      className += " _hover"
    }

    return className
  }

  return (
    <div className={getClassName()}>

      <div className="flex">

        <div onClick={click} 
          className={props.active ? "checkbox checkbox-active flex" : "checkbox"}>
          { props.active &&
          <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          }
        </div>
        
        { props.text &&
        <div className="checkbox-label">
          {props.text}
        </div>
        }

      </div>

    </div>

  )
}

export default Checkbox