/**
 * RESET-SCROLLER
 * Hide scroller for a few seconds (wait for the question animation to end)
 */
const resetScroller = () => {
  const page = document.getElementById("page")
  if (page){
    page.style.overflowY = 'hidden'
    setTimeout(() => {
      page.style.overflowY = 'auto'
    }, 3000)
  }
}

export default resetScroller