/**
 * CODE-INPUT.TSX
 */

import { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

interface OwnProps{
  code: string
  onSubmit?: Function
}

type Props = OwnProps & WithTranslation

const regExp: RegExp = /[0-9A-Za-z]/

const CODE_LENGTH: number = 8

const BACKSPACE_KEY: number = 8
const LEFT_KEY: number = 37
const RIGHT_KEY: number = 39
const DELETE_KEY: number = 46
const ENTER_KEY: number = 13

function CodeInput(props: Props) {
  const { t } = props

  const [typedCode, setTypedCode] = useState<string>((props.code.toUpperCase() + "_".repeat(CODE_LENGTH)).substring(0, CODE_LENGTH))

  function submit(){
    if(props.onSubmit){
      props.onSubmit(typedCode)
    }
  }

  function typeChar(index: number, event: any){

    if (event.keyCode !== BACKSPACE_KEY && event.keyCode !== DELETE_KEY && typedCode[index] === "_") {
      const isCharValid: boolean = regExp.test(event.target.value) && event.target.value.length === 1

      if(isCharValid){
        setTypedCode(typedCode.substring(0, index) + event.target.value.toUpperCase() + typedCode.substring(index + 1))
  
        if ( index < CODE_LENGTH && event.target.nextElementSibling && event.target.nextElementSibling.nodeName === "INPUT" && typedCode[index + 1] === "_"){
          event.target.nextElementSibling.focus();
        }
      }
      else{
        console.log(event.target.value + ": illegal character")
      }

    }
  }


  function editChar(index: number, event: any){
    if(event.keyCode === RIGHT_KEY){
      if(index < CODE_LENGTH){
        event.target.nextElementSibling.focus()
      }
    }
    else if(event.keyCode === LEFT_KEY){
      if(index > 0){
        event.target.previousElementSibling.focus()
      }
    }
    else if (event.keyCode === BACKSPACE_KEY || event.keyCode === DELETE_KEY) {
      if(index === CODE_LENGTH || typedCode[index] !== "_"){
        setTypedCode(typedCode.substring(0, index) + "_" + typedCode.substring(index + 1))
      }
      else if(index > 0){
        setTypedCode(typedCode.substring(0, index - 1) + "_" + typedCode.substring(index))
        if ( event.target.previousElementSibling && event.target.previousElementSibling.nodeName === "INPUT" ){
          event.target.previousElementSibling.focus()
        }
      }

    }

    else if(typedCode[index] !== "_"){
      const isCharValid: boolean = regExp.test(event.key) && event.key.length === 1

      if(isCharValid){
        setTypedCode(typedCode.substring(0, index) + event.key.toUpperCase() + typedCode.substring(index + 1))
        event.target.nextElementSibling.focus();
      }
      else{
        console.log(event.key + ": illegal character")
      }
    }
    
  }

  function enter(event: any){
    if(event.keyCode === BACKSPACE_KEY || event.keyCode === DELETE_KEY){
      setTypedCode(typedCode.substring(0, CODE_LENGTH - 1) + "_" + typedCode.substring(CODE_LENGTH))
      event.target.previousElementSibling.focus()
    }
    else if(event.keyCode === ENTER_KEY && checkCode()){
      submit()
    }
  }

  function checkCode(){
    return typedCode.length === CODE_LENGTH && new RegExp(`[0-9A-Za-z]{${CODE_LENGTH}}`).test(typedCode)
  }

  return (
    <div style={{
      fontSize: 28,
      height: 120
    }}>
      <div
        className="flex"
        style={{
          position: "relative"
        }}
      >

        { Array.from(Array(CODE_LENGTH)).map((x: any, i: number) =>
        <input
          key={i}
          className="_hover flex1"
          type="text"
          autoComplete="off"
          style={{
            marginLeft: i > Math.floor(CODE_LENGTH / 2 - 1) ? 10 : 0,
            marginRight: i < Math.ceil(CODE_LENGTH / 2) ? 10 : 0,
            textAlign: "center",
            fontSize: "inherit",
            zIndex: 1
          }}
          autoFocus={i === (props.code.length < CODE_LENGTH ? props.code.length : 0)}
          size={1}
          maxLength={1}
          required pattern="[0-9A-Z]{1}"
          onKeyUp={(e: any) => editChar(i, e)}
          onChange={(e: any) => typeChar(i, e)}
          value={typedCode[i] === "_" ? "" : typedCode[i]}
        />
        )}

        <input
          type="button"
          className={( checkCode() ? "_hover " : "" ) + "button primary"}
          value={t("question_confirm")}
          style={{
            position: "absolute",
            top:50,
            backgroundColor: checkCode() ? "#1CB06E" : "#d9d9d9",
            color: "white",
            borderStyle: "none",
            width:"100%",
            fontSize: 18
          }}
          onKeyUp={enter}
          onClick={checkCode() ? () => submit() : undefined}
        />

        <div style={{
          position: "absolute",
          margin: "auto",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }}>
          {" - "}
        </div>

        { //For debugging
        /**
        <div style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 150,
          bottom: 0
        }}>
          {typedCode}
        </div>
        /**/
        }

      </div>
    </div>
  )
  
}

export default withTranslation()(CodeInput)