/**
 * WARNING-MODAL
 * Message if user can not vote
 */

import { WithTranslation, withTranslation } from "react-i18next"
import Button from "./button"

interface OwnProps extends WithTranslation{
  text : string
  onClose : Function
}

function TestModal(props:OwnProps) {
  const { t } = props

  return (
    <div className="warning-modal-container flex">
      <div className="warning-modal">
          
        <div style={{ marginBottom : 8 }}>
          <b>{props.text}</b>
        </div>
        
        <Button onClick={() => props.onClose()}
          className="primary">
          {t("back")}
        </Button>

      </div>
    </div>
  )
  
}

export default withTranslation()(TestModal)