/**
 * PAGE.TS
 * Container for quickvote app
 */
import { useEffect } from "react";
import { config } from "@/config"
import { detect } from 'detect-browser';
import { WithTranslation, withTranslation } from "react-i18next";

interface OwnProps{
  backgroundColor? : string
  color? : string
  footer? : string
  children? : any
  tabTitle?: string
}

type Props = OwnProps & WithTranslation

function Page(props: Props) {
  const { t } = props
  const browser = detect()

  useEffect(() => {
    document.title = props.tabTitle ? props.tabTitle : t("qvt_survey")
  }, [
    t,
    props.tabTitle
  ])

  //Detect safari (will not display flex-dcol)
  const isSafari = browser ? config.alternativeDisplayBrowsers.indexOf(browser.name) > -1 : false

  return (
    <div
      id="page"
      className={isSafari ? "page" : "page flex flex-dcol" }
      style={{
        backgroundColor : props.backgroundColor,
        color : props.color
      }}>

      <div className="flex1"/>
      <div className="flex">
        <div className="flex1"/>
        {props.children}
        <div className="flex1"/>
      </div>

      <div className="flex1"/>

      { props.footer &&
      <div className="page-footer" style={{ color : "#a0a0a0" }}>
        {props.footer}
      </div>
      }

    </div>
  )
  
}

export default (withTranslation()(Page))