/**
 * USER.MODEL.TS
 * User
 */


export class User{
  id: string = ""
  email: string = ""
  firstname: string = ""
  lastname: string = ""
  language: string = "fr"
  
  constructor(user: Partial<User> = {}){
    Object.assign(this, user)
  }

}