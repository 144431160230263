/**
 * TOPIC.MODEL.TS
 * Card of the template
 */

import { Axis } from "./axis.model"
import { Question } from "./question.model"

export class Topic{
  aid : number = 0
  Axis : Axis = new Axis()
  AxisId : string | null = null
  id : string = ""
  isAtStart : boolean = false
  done : boolean = false
  fullScreen : boolean = false
  name : any = {}
  questionPrimaryId : string = ""
  Questions : Question[] = []
  remove:boolean = false
  
  constructor(topic: Partial<Topic> = {}){
    if (!topic) topic = new Question()
    topic.Axis = new Axis(topic.Axis)
    topic.Questions = topic.Questions ? topic.Questions.map(x => new Question(x)) : []
    Object.assign(this, topic)
  }

  get notVisibleQuestions():Question[]{
    return this.Questions.filter(x => !x.visible)
  }
  
}