/**
 * FORMAT-DATE.UTILS
 * Format a date to string
 */

export function formatDate(date: Date | number | string, withTime: boolean): string{
  date = new Date(date)

  if (withTime){
    return date.toLocaleDateString(navigator.language, {
      hour : '2-digit',
      minute :'2-digit'
    })
  }else{
    return date.toLocaleDateString()
  }

}