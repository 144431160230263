/**
 * ACOUNT-COLORS.MODEL.TS
 * Customer colors for an account
 */

export class Colors{
  active : string = "#FFA01A"
  brandPrimary : string =  "#FFA01A"
  brandSecondary : string =  "#ffab4a"
  brandText : string =  "#FFFFFF"
  cta : string =  "#51E899"

  constructor(colors: Partial<Colors> = {}){
    Object.assign(this, colors)
  }
  
}