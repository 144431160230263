/**
 * BUTTON.TSX
 */
import { Session } from "@/model/session.model"
import { connect } from "react-redux"
import ReactTooltip from 'react-tooltip'

interface StateProps{
  session : Session
}

interface OwnProps{
  backgroundColor? : string
  color? : string
  isLarge? : boolean,
  className? : string
  onClick : Function,
  children? : any
  tooltip? : string
}

type Props = StateProps & OwnProps

function Button(props:Props) {

  //Trigger action onClick
  function click(){
    props.onClick()
  }

  //Style of the button depends of the given class
  //> primary, secondary, link
  function getStyle(){
    let backgroundColor
    let color
    let textDecoration
    let textTransform

    if (props.className === "primary"){
      backgroundColor = props.session.colors.cta
      color = props.session.colors.brandText
    }else if (props.className === "secondary"){
      backgroundColor = "#d9d9d9"
      color = "white"
    }else if (props.className === "link"){
      color = "#a0a0a0"
      textDecoration = "underline"
      textTransform = "initial"
    }else{
      color = "#a0a0a0"
    }

    if (props.backgroundColor){
      backgroundColor = props.backgroundColor
    }
    
    if (props.color){
      color = "white"
    }

    return {
      backgroundColor,
      color,
      textDecoration,
      textTransform
    }

  }

  return (
    <div data-tip={props.tooltip}>
      <div className="_hover button"
        onClick={click}
        style={getStyle()}>
        {props.children}
      </div>
      <ReactTooltip />
    </div>
  )
  
}

const mapStateToProps = state => ({
  session : state.reducers
})

export default connect(mapStateToProps)(Button)