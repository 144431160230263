/**
 * TEMPLATE-OPTIONS.MODEL.TS
 * Options for the template
 */

export class TemplateOptions {
  emojiMode: boolean = false
  hideAxisPage: boolean = false
  hideQuestionSecondaryText: boolean = false //Hide text for secondary question
  responseEmojiLabel_no_fr: string = ""
  responseEmojiLabel_yes_fr: string = ""
  showCircle: boolean = false
  triggerNegativeAnswer: number = 50 //Trigger to display question negative
  triggerPositiveAnswer: number = 50 //Trigger to display question positive
  triggerNegativeAnswerNps: number = 70 //Trigger to display question negative (NPS)
  triggerPositiveAnswerNps: number = 70 //Trigger to display question positive (NPS)
  constructor(item: Partial<TemplateOptions> = {}) {
    Object.assign(this, item)
  }
}
