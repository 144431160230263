/**
 * SESSION
 * Item used in reducer
 */

import { Colors } from "./colors.model"
import { Topic } from "./topic.model"
import { uniqBy } from "lodash"
import { Axis } from "./axis.model"
import { MessageOptions } from "./message-options.model"
import { AxisRule } from "./axis-rule.model"
import { TemplateOptions } from "./template-options.model"
import { t } from "@/translate/t"

export class Session{
  accountId: string = ""
  accountImage: string | null = null
  accountName: string = ""
  ceilTesters: number = 5
  colors: Colors = new Colors()
  currentAxis: string | null = null
  currentQuestionSecondary: boolean = false
  imageUrl: string = require("@/assets/intro.png")
  feedbackId : string | null = null
  isPreview: boolean = false
  isTest: boolean = true
  isTrialAccount : boolean = false
  language: string = ""
  languages: string[] = []
  messageOptions: MessageOptions = new MessageOptions()
  participationMin: number = 5
  resultsCode: string | null = null                           //code required in url fdr user to access results
  role: string | null = null                                  //ADMIN or null
  saveResults: boolean = false
  segmentationByAxesRules: AxisRule[] = []
  status: string = ""
  surveyName: string = ""
  showTestModal: boolean = false
  templateOptions: TemplateOptions = new TemplateOptions()
  topics: Topic[] = []
  userAttributes: string[] = []
  usersForTest: string[] = []

  constructor(session: Partial<Session> = {}){
    if (!session) session = new Session()
    session.colors = new Colors(session.colors)
    session.messageOptions = new MessageOptions(session.messageOptions)
    session.templateOptions = new TemplateOptions(session.templateOptions)
    session.topics = session.topics ? session.topics.map(x => new Topic(x)) : []
    Object.assign(this, session)
  }

  get axes():Axis[]{
    const axes = this.topics.map(x => x.Axis).filter(x => x.id !== "" && x.id !== "*")
    return uniqBy(axes, "id")
  }

  get axesCount():number{
    return this.axes.length
  }

  get axesCountDone():number{
    const axes = uniqBy(this.topicsDone.map(x => x.Axis), "id").filter(x => x.id !== "*")
    if (this.topicsDone.filter(x => x.AxisId === this.currentAxis).length === 0){
      return axes.length + 1
    }else{
      return axes.length
    }
  }

  get topicsDone():Topic[]{
    return this.topics.filter(x => x.done)
  }

  get topicsUndone():Topic[]{
    return this.topics.filter(x => !x.done)
  }

  getMessageOptions(language, type : "title" | "content" | "titleSecondary"){
    if (this.messageOptions[type][language]){
      return this.messageOptions[type][language] 
    }else if (this.messageOptions[type]["en"]){
      return this.messageOptions[type][language]
    }else{
      return this.messageOptions[type][this.languages[0]]
    }
  }

  //Get label for every response
  getResponseLabel(responseCount:number, key:string | number, labelPrefix:string):string{
    
    if (responseCount === 4){
      responseCount = 5
      if (key === 3 || key === 4){
        key = key + 1
      }
    }

    if (this.templateOptions["response" + responseCount + "Label"]){
      return this.templateOptions["response" + responseCount + "Label_" + key + "_" + this.language]
    }else{
      return t(labelPrefix + "_" + key)
    }

  }

}