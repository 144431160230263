/**
 * API-MIDDLEWARE.TS
 * 
 * Apply specific behavior for action of type "BACKEND"
 * Transform action into promise used to communicate with the server
 * Error message is defiend by a { error } object in the response
 */
import { toast } from 'react-toastify';
import { backendInstance, reportingInstance } from "@/index";
import { t } from '@/translate/t';

function processError(errorResponse: any){

  let code: string

  if (errorResponse.status === 404) code = "utils_server_empty"
  else if (errorResponse.status >= 500) code = "utils_network_error"
  else if (errorResponse.status === 403) code = "utils_server_unauthorized"
  else if (errorResponse.status === 401) code = 'utils_server_expired'
  else code = errorResponse.data.error

  return code
    
}

const apiMiddleware = () => next => action => {
  return new Promise(function(resolve) {
    next(action);
    
    //Only for action of type backend
    //Else do not apply middleware
    if (action.type !== 'ARCHIVE' && action.type !== 'BACKEND') return

    //Params for "BACKEND" action
    //- method : type of méthode (POST, GET, PUT, DELETE)
    //- data : form parameters to submit (can be POST or GET)
    //- url : path for the resource
    const { method, data, url } = action.payload
  
    //Format params 
    const dataOrParams = ["GET", "DELETE"].indexOf(method) > -1 ? "params" : "data";

    switch(action.type){

      case 'ARCHIVE':

        reportingInstance.request({
          url,
          method : "GET"
        }).then(({ data }) => {

          if (!data){
            data = {}
          }
          else if (data.error){
            toast(data.error, { type : 'error' });
            resolve({ error : data.error })
          }
          else{
            resolve(data)
          }

        }).catch(error => {

          if (error.response){

            const code: string = processError(error.response)

            toast(t(code) + error.response.data.details, { type : 'error' });
            resolve({ error : code });

          }
          else{
            toast(t("middleware_error_timeout", { instance: "Archive" }), { type : 'error' });
            resolve({ error : 'network', details : error });
          }

        })

        break

      case 'BACKEND':

        //Do request and then handle response or errors
        backendInstance.request({
          url : url,
          method : method,
          [dataOrParams]: data
        }).then(({ data }) => {

          if (data.error){
            if (data.error !== 'invitation_done'){
              toast(t("error_" + data.error ), { type : 'error' })
            }
          }

          resolve(data); 

        })
        .catch(error => { 
          if (error.response){

            const code: string = processError(error.response)
            
            toast(t("error_" + code, error.response.data.details), { type : 'error' });
            resolve({ error : code })

          }
          else{
            toast(t("error_network"), { type : 'error' });
            resolve({ error : 'network', details : error });
          }
        })

        break

      default :
        //error
        toast(t("error_uncorrect_action_type"), { type : 'error' });
        break

      }

  })

}

export default apiMiddleware