import env from "@/env"

export function getImageUrl(projectResponse: any): string {

  if(projectResponse.image && projectResponse.customImage){
    return env.REACT_APP_URL_SPACE.concat("/Projects/", projectResponse.id, ".png?" + new Date().getTime())
  }
  else{
    if(projectResponse.Template && projectResponse.Template.image){
      return env.REACT_APP_URL_SPACE.concat("/Templates/", projectResponse.Template.image, ".png?" + new Date().getTime())
    }
    else{
      return require("@/assets/intro.png")
    }
  }
}