/**
 * CONSTANTS
 */

export const ADD_USER_FOR_TEST: string = 'ADD_USER_FOR_TEST'
export const BACK: string = 'BACK'
export const CANCEL_FULLSCREEN: string = 'CANCEL_FULLSCREEN'
export const CLOSE_TEST_MODAL: string = 'CLOSE_TEST_MODAL'
export const EDIT_CURRENT_AXIS: string = 'EDIT_CURRENT_AXIS'
export const EDIT_DONE: string = 'EDIT_DONE'
export const EDIT_LANGUAGE: string = 'EDIT_LANGUAGE'
export const EDIT_MESSAGE: string = 'EDIT_MESSAGE'
export const EDIT_NOTE: string = 'EDIT_NOTE'
export const EDIT_RESPONSE: string = 'EDIT_RESPONSE'
export const EDIT_STATUS: string = 'EDIT_STATUS'
export const EDIT_TOPICS: string = 'EDIT_TOPICS'
export const GET_SESSION: string = 'GET_SESSION'
export const INIT_SESSION: string = 'INIT_SESSION'
export const OPEN_NEXT_QUESTION: string = 'OPEN_NEXT_QUESTION'
export const RESET_SECONDARY: string = 'RESET_SECONDARY'