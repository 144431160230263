/**
 * LOGIN.ROUTE.TSX
 * Entrypoint for quickvote app
 * Auth the user then redirect
 */

import { useEffect, useState } from "react"
import Page from "../components/page"
import { connect } from "react-redux"
import cookie from 'react-cookies'
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import { WithTranslation, withTranslation } from 'react-i18next'
import { store, backendInstance } from "@/index"
import { fetchProject, fetchTemplate, fetchTopicsPublic, getSession, _auth } from "@/redux/actions"
import { Session } from "@/model/session.model"
import { Colors } from "@/model/colors.model";
import { MessageOptions } from "@/model/message-options.model";
import { Topic } from "@/model/topic.model";
import { AxisRule } from "@/model/axis-rule.model";
import { intersection } from "lodash"
import { TemplateOptions } from "@/model/template-options.model";
import { Question } from "@/model/question.model";
import { getImageUrl } from "@/utils/get-image-url";
import Card from "@/components/card";
import CodeInput from "@/components/code-input";
import InvitationModal from "@/components/invitation-modal";
import Loader from "@/components/loader";
import ErrorMessage from "@/components/error-message";

interface StateProps extends WithTranslation, RouteComponentProps{
  session : Session
}

const EMAIL_SUPPORT: string = "support@id-tree.com"

const MODAL_CODE: string = "MODAL_CODE"

//Home page to connect
//Call login function to load data, then redirect
function LoginRoute(props:StateProps) {
  const { t } = props

  //Get params
  const { code } = useParams<any>()

  //Display error message
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isLoading, setIsLoading] = useState(true)
  const [accessCode, setAccessCode] = useState<string>(code ? code : "")
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  useEffect(() => {

    let error: boolean = false

    //Init Session objects
    let templateOptions: TemplateOptions = new TemplateOptions()
    let participationMin: number = 5
    let colors: Colors = new Colors()
    let accountId: string = ""
    let accountImage: string | null = null
    let accountName: string = ""
    let messageOptions: MessageOptions = new MessageOptions()
    let language: string = navigator.language ? navigator.language.split("-")[0] : "en"
    let languages: string[] = []
    let resultsCode: string | null = null
    let saveResults: boolean = false
    let surveyName: string = ""
    let imageUrl: string = require("@/assets/intro.png")
    let topics: Topic[] = []
    let showTestModal: boolean = false
    let segmentationByAxesRules: AxisRule[] = []
    let currentAxis: string | null = null
    let isTest: boolean = false
    let isTrialAccount : boolean = false
    let usersForTest: string[] = []
    let role: string | null = null
    let ceilTesters: number = 5

    //Get params from the url
    //> faq : redirect user to faq page
    //> id : get id for a participation token
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search)
    const faq: boolean = searchParams.get("info") === 'true'
    const lg: string | null = searchParams.get("language")
    const templateId: string | null = searchParams.get("templateId")
    const questionId: string | null = searchParams.get("questionId")
    const projectId: string | null = searchParams.get("projectId")
    const isPreview: string | null = searchParams.get("isPreview")

    async function login(){

      //If templateId is specified search template informations
      if (templateId){

        //Get topics and template
        const topicsResponse: any = await store.dispatch(fetchTopicsPublic(templateId ? templateId : ""))
        const templateResponse: any = await store.dispatch(fetchTemplate(templateId ? templateId : ""))
        const projectResponse: any = projectId ? await store.dispatch(fetchProject(projectId)) : {}

        //Handle error
        if (
          topicsResponse.error || 
          templateResponse.error || 
          projectResponse.error
        ){
          error = true
          setErrorMessage(templateResponse.error)
          setIsLoading(false)
        }
        //Save data in order to save them in the store
        else{
          const availableLanguages: string[] = projectResponse.languages ? intersection(templateResponse.languages, projectResponse.languages) : templateResponse.languages
          
          templateOptions = templateResponse.options
          participationMin = templateResponse.Account ? templateResponse.Account.participationMin : 5
          colors = templateResponse.Account ? templateResponse.Account.colors : new Colors()
          accountId = templateResponse.Account ? templateResponse.Account.id : ""
          accountImage = templateResponse.Account ? (templateResponse.Account.options.image ? templateResponse.Account.id : null) : null
          accountName = templateResponse.Account ? templateResponse.Account.name : ""
          language = lg ? lg : availableLanguages[0]
          languages = availableLanguages
          topics = questionId ? topicsResponse.map((x:any)=>{return{...x, done: x.Questions.every((question: any)=>question.id !== questionId)}}) : topicsResponse
          showTestModal = true

          if(projectResponse){
            messageOptions = projectResponse.messageOptions
            surveyName = projectResponse.name
            imageUrl = getImageUrl({id: projectResponse.id, image: projectResponse.image, customImage: projectResponse.customImage, Template: {image: templateResponse.image}})
          }

          if(questionId){

            //Init tropics
            topics.forEach((x: any)=>x["done"] = false)

            //Get index of the selected questionId
            const index: number = topics.findIndex((topic: any)=>topic.Questions.some((question: any)=>question.id === questionId))

            //Update current Axis
            currentAxis = topics[index].AxisId

            //Make all questions before the selected one as done = true
            if(index > 0){
              for(let i: number = 0; i < index; i++){
                topics[i]["done"] = true
                topics[i].Questions.forEach((question: Question)=>question.done = true)
              }
            }

          }
          
        }

      }
      //Else if login from a code
      else{

        if(accessCode.length > 0){

          //Authenticate from invitation id
          const response:any = await store.dispatch(_auth(accessCode))

          //Save JWT
          cookie.save('idtreeToken', response.token, { path: '/' })
          cookie.save('firebaseToken', "null", { path: '/' });
          backendInstance.defaults.headers.common['idtreeToken'] = response.token
          backendInstance.defaults.headers.common['firebaseToken'] = "null";

          //Handle error
          if (response.error){
            error = true
            setErrorMessage(response.error)
            setIsLoading(false)
          }else{

            templateOptions = response.invitation.template.options
            participationMin = response.project.Account.participationMin
            colors = response.project.Account.colors
            accountId = response.project.Account.id
            accountImage = response.project.Account.options.image ? response.project.Account.id : null
            accountName = response.project.Account.name
            language = response.invitation.language
            languages = response.project.languages
            messageOptions = response.project.messageOptions
            saveResults = true
            surveyName = response.survey.name
            topics = response.invitation.template.topics
            resultsCode = response.invitation.results_code ? response.invitation.results_code : null
            imageUrl = getImageUrl({id: response.project.id, image: response.project.image, customImage: response.project.customImage, Template: {image: response.invitation.template.image}})
            isTest = response.survey.isTest
            isTrialAccount = response.project.Account.level === "free"
            usersForTest = response.project.usersForTest
            role = response.isAdmin ? "ADMIN" : null
            ceilTesters = response.project.Account.options.ceilsTesters ? response.project.Account.options.ceilsTesters : 5
            
            //Add segmentation
            if (response.project.isSegmentationActive){
              segmentationByAxesRules = response.project.segmentationByAxesRules.map(x => new AxisRule(x))
            }

          }
        }

        else{
          error = true
          setErrorMessage("no_code")
          setIsLoading(false)
        }

      }

      //If no error
      if (!error){

        //Reset URL params
        window.history.pushState({}, '', window.location.pathname)

        //Save data in store
        const session: Session = new Session({
          templateOptions,
          participationMin,
          colors,
          accountId,
          accountImage,
          accountName,
          currentAxis,
          imageUrl,
          isPreview: false,
          language,
          languages,
          messageOptions,
          resultsCode,
          saveResults,
          segmentationByAxesRules,
          surveyName,
          topics,
          showTestModal,
          isTest,
          isTrialAccount,
          usersForTest,
          role,
          ceilTesters
        })

        document.documentElement.setAttribute("lang", language)
        store.dispatch(getSession(session))
        
        if (session.topicsDone.length === 0){
          if (faq){
            props.history.push("/faq")
          }else if(templateId && !projectId){
            props.history.push("/questions")
          }else{
            props.history.push("/intro")
          }
        }else{
          props.history.push("/questions")
        }

      }

    }

    async function loginPreview(){

      const projectResponse: any = await store.dispatch(fetchProject(projectId!))
  
      //Reset URL params
      window.history.pushState({}, '', window.location.pathname)

      //Update language
      language = projectResponse.languages[0]

      //Save data in store
      const session: Session = new Session({
        isPreview : true,
        language,
        languages: projectResponse.languages,
        messageOptions : projectResponse.messageOptions,
        surveyName : projectResponse.name,
        imageUrl : getImageUrl({id: projectResponse.id, image: projectResponse.image, customImage: projectResponse.customImage, Template: null})
      })

      document.documentElement.setAttribute("lang", language)
      store.dispatch(getSession(session))
      props.history.push("/intro")
      
    }

    if(( !templateId || templateId.length < 1 ) && projectId && isPreview){
      loginPreview()
    }
    else{
      login()
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessCode
  ]);

  return (
    <Page
      tabTitle={props.session.surveyName}
    >
      { currentModal === MODAL_CODE &&
      <InvitationModal onClose={()=>setCurrentModal(null)}/>
      }

      { !isLoading
      ?
      (
        errorMessage === "invitation_done"
        ?
        <div>

          <ErrorMessage message={t("login_used_title")}/>
          
          <p>
            {t("login_used_text_1")}
          </p>
          
          <ul>
            <li>{t("login_used_text_2")}</li>
            <li>{t("login_used_text_3")}</li>
          </ul>


          <p>
            <b>{t("login_used_support")}</b>
          </p>

          <ul>
            <li>{EMAIL_SUPPORT}</li>
          </ul>

          <div style={{ height : '40px' }}/>

        </div>
        :
        <div className="login-message">
          <div className="flex">
            <div className="flex1" />
            <div>

              <Card>
                
                <form className="flex flex-dcol">

                  <div>
                    <p>
                      {t("code_enter")}
                    </p>
                  </div>

                  <CodeInput
                    code={accessCode.replace(/[^0-9A-Za-z]/g, "")}
                    onSubmit={(typedCode: string)=>setAccessCode((typedCode.slice(0, 4) + "-" + typedCode.slice(4)).toLowerCase())}
                  />

                  <div style={{height: 10}} />

                  <div className="flex" style={{height: 10, zIndex: 1}}>
                    <div className="flex1" />
                    <u
                      className="_hover"
                      style={{color: "#8C8C8C"}}
                      onClick={()=>setCurrentModal(MODAL_CODE)}  
                    >{t("code_find")}</u>
                  </div>
                  
                  <div style={{height: 10}} />
                  
                </form>


              </Card>

            </div>
            
            <div className="flex1" />

          </div>
        </div>
      )
      :
      <Loader/>
      }
    </Page>
  )
  
}

const mapStateToProps = state => ({
  session : state.reducers
})

export default withRouter(connect(mapStateToProps)(withTranslation()(LoginRoute)))