/**
 * CARD-BUTTON.TSX
 */
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Session } from "@/model/session.model"
import { connect } from "react-redux"

interface StateProps{
  session : Session
}

interface OwnProps{
  isCta? : boolean
  onClick : Function
  text : string
  icon : IconDefinition
}

type Props = StateProps & OwnProps

function CardButton(props:Props) {

  function click(){
    props.onClick()
  }

  function getStyle(){
    let backgroundColor
    let borderColor
    let color

    if (props.isCta){
      backgroundColor = props.session.colors.cta
      borderColor = props.session.colors.cta
      color = "white"
    }else{
      borderColor = "#ccc"
    }

    return {
      backgroundColor,
      borderColor,
      color
    }
  }

  return (
    <div className="_hover card-button flex flex-dcol"
      onClick={click}
      style={getStyle()}>
      <div>
        <FontAwesomeIcon icon={props.icon}
          style={{ color : props.isCta ? "white" : "#ccc"}}
        />
      </div>
      <div className="flex1"/>
      <div>
        {props.text}
      </div>
      <div className="flex1"/>
    </div>
  )
  
}

const mapStateToProps = state => ({
  session : state.reducers
})

export default connect(mapStateToProps)(CardButton)