/**
 * REDUCERS.TS
 */
import { Axis } from "@/model/axis.model"
import { Proposition } from "@/model/proposition.model"
import { Question } from "@/model/question.model"
import { Session } from "@/model/session.model"
import { Topic } from "@/model/topic.model"
import i18n from "@/translate/i18n"
import {
  ADD_USER_FOR_TEST,
  BACK,
  CANCEL_FULLSCREEN,
  CLOSE_TEST_MODAL,
  EDIT_CURRENT_AXIS,
  EDIT_DONE,
  EDIT_LANGUAGE,
  EDIT_MESSAGE,
  EDIT_NOTE,
  EDIT_RESPONSE,
  EDIT_STATUS,
  EDIT_TOPICS,
  GET_SESSION,
  INIT_SESSION,
  OPEN_NEXT_QUESTION,
  RESET_SECONDARY
} from "./constants"

let session: Session
let topics: Topic[]
let userAttributes: string[]

let currentAxis: string | null
let currentTopic
let currentTopics
let currentQuestion
let currentQuestionPrimary
let currentQuestionSecondary
let currentProposition
let nextTopic
let noteCeil

const initialState = new Session()

const reducer = function (state = initialState, action) {
  switch (action.type) {
    //In test mode after inviting a new user, update users array
    case ADD_USER_FOR_TEST:
      state.usersForTest = [...state.usersForTest, action.payload.userId]
      return new Session(state)

    //Go back in the survey
    //Reset all the vote for the current axis
    case BACK:
      currentTopics = state.topics
      currentTopics.forEach((topic) => {
        if (topic.AxisId === action.payload.axisId) {
          delete topic.done

          topic.Questions.forEach((question) => {
            delete question.done
            delete question.visible
            delete question.note
            delete question.valueSpecial

            question.Propositions.forEach((proposition) => {
              delete proposition.active
              delete proposition.valueSpecial
            })
          })
        }
      })
      return new Session({
        ...state,
        topics: currentTopics
      })

    //Reset visible for current topic
    //Set previous as done = false
    case CANCEL_FULLSCREEN:
      currentTopic = state.topics.find((x) => x.id === action.payload.topicId)
      currentTopic.Questions.forEach((question) => {
        question.done = false
        question.visible = false
      })

      currentTopics = state.topics.map((x) => (x.id === currentTopic.id ? currentTopic : x))

      if (state.topicsDone.length) {
        nextTopic = state.topicsDone.reverse()[0]
        nextTopic.done = false
        currentTopics = currentTopics.map((x) => (x.id === nextTopic.id ? nextTopic : x))
      }

      return new Session({
        ...state,
        topics: currentTopics
      })

    //Reset visible for current topic
    //Set previous as done = false
    case CLOSE_TEST_MODAL:
      return new Session({
        ...state,
        showTestModal: false
      })

    //Change current axis for the survey
    case EDIT_CURRENT_AXIS:
      return new Session({
        ...state,
        currentAxis: action.payload.axisId
      })

    //Reactivate topic already done
    case EDIT_DONE:
      currentTopic = state.topics.find((x) => x.id === action.payload.topicId)
      currentTopic.done = false
      return new Session({
        ...state,
        topics: state.topics.map((x) => (x.id === currentTopic.id ? currentTopic : x))
      })

    //Edit value for the session
    case EDIT_LANGUAGE:
      return new Session({
        ...state,
        language: action.payload.language
      })

    //Edit message for open question
    case EDIT_MESSAGE:
      currentTopic = state.topics.find((x) => x.id === action.payload.topicId)
      currentQuestion = currentTopic.Questions.find((x) => x.id === action.payload.questionId)

      if (currentQuestion) {
        currentQuestion.message = action.payload.message
      }

      return new Session({
        ...state,
        topics: state.topics.map((x) => (x.id === currentTopic.id ? currentTopic : x))
      })

    //Edit value for the session
    case EDIT_STATUS:
      return new Session({
        ...state,
        status: action.payload.status
      })

    //Edit note numeric
    //If reverse (ex "Agree" is negative answer) ==> note = 100 - note
    //IF special tag (RPS) set value special for the question
    //> special case for psychological job demands who is always reverse (positive QVT answer is burnout risk)
    case EDIT_NOTE:
      currentTopic = state.topics.find((x) => x.id === action.payload.topicId)
      currentQuestion = currentTopic.Questions.find((x) => x.id === action.payload.questionId)

      if (currentQuestion.reverse && action.payload.note !== null) action.payload.note = 100 - action.payload.note
      currentQuestion.note = action.payload.note

      if (currentQuestion.special) {
        if (currentQuestion.note === null) currentQuestion.valueSpecial = null
        else if (currentQuestion.note < 25) currentQuestion.valueSpecial = 1
        else if (currentQuestion.note < 50) currentQuestion.valueSpecial = 2
        else if (currentQuestion.note > 75) currentQuestion.valueSpecial = 4
        else currentQuestion.valueSpecial = 3

        if (currentQuestion.special === "psychological-job-demands" && currentQuestion.valueSpecial) {
          currentQuestion.valueSpecial = 5 - currentQuestion.valueSpecial
        }
      }

      return new Session({
        ...state,
        topics: state.topics.map((x) => (x.id === currentTopic.id ? currentTopic : x))
      })

    //Edit response for question "choice"
    //Set value special if provided
    case EDIT_RESPONSE:
      currentTopic = state.topics.find((x) => x.id === action.payload.topicId)
      currentQuestion = currentTopic.Questions.find((x) => x.id === action.payload.questionId)
      currentProposition = currentQuestion.Propositions.find((x) => x.id === action.payload.propositionId)
      currentProposition.active = !currentProposition.active
      currentProposition.valueSpecial = currentProposition.active ? action.payload.special : null

      //If only one response allowed, reset other response at null
      if (currentQuestion.choiceCount === 1) {
        currentQuestion.Propositions.forEach((proposition) => {
          if (proposition.id !== currentProposition.id) {
            proposition.active = false
            proposition.valueSpecial = null
          }
        })
      }

      //If current topic is from axis filter update user attributes
      userAttributes = state.userAttributes
      if (currentTopic.AxisId === "*") {
        if (currentProposition.active) {
          userAttributes = userAttributes.concat(currentProposition.attributeId)
        } else {
          userAttributes = userAttributes.filter((x) => x !== currentProposition.attributeId)
        }
      }

      //Change order for all the proposition
      if (currentQuestion.choiceCount < 0) {
        if (currentProposition.active) {
          currentProposition.order = currentQuestion.Propositions.filter((x) => x.active).length
        } else {
          currentQuestion.Propositions.forEach((proposition) => {
            if (proposition.id !== currentProposition.id && proposition.order >= currentProposition.order) {
              proposition.order = proposition.order - 1
            }
          })

          currentProposition.order = null
        }
      }

      return new Session({
        ...state,
        userAttributes,
        topics: state.topics.map((x) => (x.id === currentTopic.id ? currentTopic : x))
      })

    //Edit topics
    //If there is segmentation by axis filter topic list
    case EDIT_TOPICS:
      topics = state.topics

      if (state.segmentationByAxesRules.length) {
        topics.forEach((topic) => {
          if (topic.AxisId) {
            const axisRule = state.segmentationByAxesRules.find((x) => x.axisId === topic.AxisId)
            if (axisRule) {
              for (let i = 0; i < axisRule.populations.length; i++) {
                const population = axisRule.populations[i]
                if (population.isTemplatePopulation && state.userAttributes.indexOf(population.id) === -1) {
                  topic.remove = true
                }
              }
            }
          }
        })
      }

      //Nettoyer list
      topics = topics.filter((x) => !x.remove)

      return new Session({
        ...state,
        topics
      })

    //Get session from auth
    //Init order for questions
    //Init proposition "other"
    case GET_SESSION:
      session = action.payload.session
      currentAxis = session.currentAxis

      //Set language
      i18n.changeLanguage(session.language)

      //Remove questions without length
      session.topics = session.topics.filter((x) => x.Questions.length > 0)

      //Edit topics
      session.topics.forEach((topic) => {
        topic.Axis = new Axis(topic.Axis)
        if (topic.isAtStart) {
          topic.Axis.id = "*"
          topic.AxisId = "*"
          if (!currentAxis) {
            currentAxis = "*"
          }
        }

        topic.Questions.forEach((question, i) => {
          question.order = i
          if (question.withMessage && !topic.isAtStart) {
            question.Propositions = question.Propositions.concat([
              new Proposition({
                id: "others"
              })
            ])
          }
        })
      })

      if (!currentAxis && session.topics[0]) {
        currentAxis = session.topics[0].AxisId
      }

      session.currentAxis = currentAxis

      return new Session(session)

    //Init session on page refresh
    case INIT_SESSION:
      i18n.changeLanguage(state.language)
      return new Session(state)

    //Detect if there is a question to answer in the same topic
    //Check triggers for that
    //If there not quesiton to follow, set the topic as done
    case OPEN_NEXT_QUESTION:
      currentTopic = state.topics.find((x) => x.id === action.payload.topicId)
      currentQuestion = currentTopic.Questions.find((x) => x.id === action.payload.questionId)
      currentQuestion.done = true
      currentQuestionSecondary = false

      //Get primary question for the topic
      currentQuestionPrimary = currentTopic.Questions[0]

      for (let i = 0; i < currentTopic.notVisibleQuestions.length; i++) {
        const question = new Question(currentTopic.notVisibleQuestions[i])

        //For question type choice
        //If there is no trigger > question is visible
        if (currentQuestionPrimary.type === "choice") {
          if (
            !question.QuestionsTopic.triggerChoice ||
            currentQuestionPrimary.activePropositions.find((x) => x.id === question.QuestionsTopic.triggerChoice)
          ) {
            currentQuestionSecondary = true
          }
        }

        //For question type scale
        //If trigger inferior at
        else if (currentQuestionPrimary.type === "scale") {
          //Alternative ceil for NPS question
          if (currentQuestionPrimary.responseCount === 10) {
            noteCeil =
              question.QuestionsTopic.triggerValue === "<"
                ? state.templateOptions.triggerNegativeAnswerNps
                : 100 - state.templateOptions.triggerPositiveAnswerNps
          } else {
            noteCeil =
              question.QuestionsTopic.triggerValue === "<"
                ? state.templateOptions.triggerNegativeAnswer
                : 100 - state.templateOptions.triggerPositiveAnswer
          }

          if (
            question.QuestionsTopic.triggerValue === "<" &&
            currentQuestionPrimary.note < noteCeil &&
            currentQuestionPrimary.note !== null
          ) {
            currentQuestionSecondary = true
          } else if (question.QuestionsTopic.triggerValue === ">" && currentQuestionPrimary.note > noteCeil) {
            currentQuestionSecondary = true
          }
        } else if (currentQuestionPrimary.type === "open") {
          currentQuestionSecondary = true
        }

        if (currentQuestionSecondary) {
          currentTopic.Questions.find((x) => x.id === question.id).visible = true
          break
        }
      }

      //If there is no secondary question find next question
      currentTopics = state.topics
      if (!currentQuestionSecondary) {
        currentTopic.done = true
        currentTopics = currentTopics.map((x) => (x.id === currentTopic.id ? currentTopic : x))

        nextTopic = currentTopics.find((x) => !x.done)
        if (nextTopic) {
          nextTopic.Questions[0].visible = true
          currentTopics = currentTopics.map((x) => (x.id === nextTopic.id ? nextTopic : x))
        }
      }

      return new Session({
        ...state,
        topics: currentTopics,
        currentQuestionSecondary
      })

    //Reset specific question (visible and done)
    case RESET_SECONDARY:
      currentTopic = state.topics.find((x) => x.Questions.find((q) => q.id === action.payload.questionId))
      currentTopic.Questions.forEach((question, i) => {
        delete question.done
        if (i > 0) {
          delete question.visible
        }
      })
      return new Session({
        ...state,
        topics: state.topics.map((x) => (x.id === currentTopic.id ? currentTopic : x)),
        currentQuestionSecondary: false
      })

    default:
      return state
  }
}

export default reducer
