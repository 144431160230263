/**
 * AXIS-RULE.MODEL.TS
 * Axis that are subject to segmentation
 */

import { Population } from "./population.modal"

export class AxisRule{
  axisId:string = ""
  populations:Population[] = []
  
  constructor(axisRule: Partial<AxisRule> = {}){
    axisRule.populations = axisRule.populations ? axisRule.populations.map(x => new Population(x)) : []
    Object.assign(this, axisRule)
  }

}