/**
 * CARD.TSX
 */
import { config } from "@/config"
import { useMediaQuery } from 'react-responsive'
import { detect } from 'detect-browser';

interface OwnProps{
  children?: any
  title?: string
  maxWidth?: number
}

function Card(props:OwnProps) {

  //No background color for safari (display bug)
  const browser = detect()
  const isSafari = browser ? config.alternativeDisplayBrowsers.indexOf(browser.name) > -1 : false

  //Set max-width if mobile display
  const isMobile = useMediaQuery({ query: '(max-width : 600px)' })

  return (
    <div className={isMobile ? "card card-sm" : "card"}
      style={{ backgroundColor : isSafari ? 'initial' : "", maxWidth : props.maxWidth }}>

      {props.title &&
      <h3>
        {props.title}
      </h3>
      }

      {props.children}

    </div>
  )
  
}

export default Card