/**
 * APP.TSX
 * Root file for react application
 * Setup routing
 */
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import EndRoute from '@/routes/end.route';
import FaqRoute from '@/routes/faq.route';
import IntroRoute from '@/routes/intro.route';
import LoginRoute from '@/routes/login.route';
import NextRoute from '@/routes/next.route';
import QuestionsRoute from '@/routes/questions.route';
import ResultsRoute from '@/routes/results.route';
import CacheBuster from 'react-cache-buster';
import pkg from '../package.json';
import FeedbackRoute from '@/routes/feedback.route';

function App() {
  return (
    <CacheBuster
      currentVersion={pkg}
      isEnabled>
      <Router>
        <Switch>
          <Route path="/end/:message"><EndRoute/></Route>
          <Route path="/faq"><FaqRoute/></Route>
          <Route path="/feedback/:id"><FeedbackRoute/></Route>
          <Route path="/intro"><IntroRoute/></Route>
          <Route path="/next"><NextRoute/></Route>
          <Route path="/questions"><QuestionsRoute/></Route>
          <Route path="/results"><ResultsRoute/></Route>
          <Route path="/:code"><LoginRoute/></Route>
          <Route path="/"><LoginRoute/></Route>
          <Redirect to="/end/404" />
        </Switch>
      </Router>
    </CacheBuster>

  );
}

export default App
