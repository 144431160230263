/**
 * INVITATION-MESSAGE.MODEL.TS
 * Content for first page
 */

export class MessageOptions{
  backgroundColor : string | null = null
  buttonColor : string | null = null
  content : any = {}
  displayImage : boolean = false
  infoContent : any = {}
  isAlternativeLayout : boolean = false
  isInfoCustom : boolean = false
  isWithTitleSecondary : boolean = false
  textColor : string | null = null
  title : any = {}
  titleSecondary : any = {}
  constructor(item: Partial<MessageOptions> = {}){
    Object.assign(this, item)
  }
}