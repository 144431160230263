/**
 * AXIS-RULE.MODEL.TS
 * Axis that are subject to segmentation
 */

export class Population{
  isTemplatePopulation : boolean = false //Is population created by the template (axis introduction)
  filterName : string = "" //Name of the filter
  id : string = "" //Id for the population (concat of filter_name and name)
  name : string = "" //Name for the population
  constructor(population: Partial<Population> = {}){
    Object.assign(this, population)
  }
}